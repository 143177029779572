body {
  text-align: center;
  color: #fff;
  background-color: #222;
  font-family: Arial, sans-serif;
}

h1 {
  margin: .5em auto;
  font-size: 3em;
}

#game-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

canvas {
  width: 100%;
  height: auto;
  display: block;
}
/*# sourceMappingURL=index.fa8dbb0f.css.map */
