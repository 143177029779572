body {
    text-align: center;
    font-family: Arial, sans-serif;
    background-color: #222;
    color: #fff;
}

h1 {
    margin: 0.5em auto;
    font-size: 3em;
}

#game-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

canvas {
    width: 100%;
    height: auto;
    display: block;
}
